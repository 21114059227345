<!-- 新冠中和抗体 -->
<template>
    <div style="padding:20px 24px 40px 24px">
        <div class="globalText" style="margin-bottom: 20px;">
            <div class="top">
                <span style="width: 225px;color: #FFFFFF;">新冠中和抗体</span>
            </div>
            <div class="content">
                <div style="display:flex;">
                    <div class="tip">检测内容</div>
                    <div class="value">中和抗体</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">检测结果<br />(浓度值[IU/ml])</div>
                    <div class="value">{{ antibodyOD }}</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">判断结果</div>
                    <div class="value">{{ antibodyRes }}</div>
                </div>
                <div style="display:flex;">
                    <div class="tip">参考范围</div>
                    <div class="value">浓度值＞80（阳性）<br />浓度值≤80（阴性）</div>
                </div>
            </div>
        </div>


        <div class="globalTitle" style="margin:30px 0 12px 0;color: #926BFF;">指标解释</div>
        <div class="globalText">
            <p style="margin-bottom: 14px;">
                中和抗体（NAb）由骨髓中的B细胞产生。为了阻止病原体感染人体，中和抗体发展出了不同的针对策略：1）包膜病毒：阻止病毒附着和进入细胞；2）非包膜病毒：替代宿主细胞与病毒衣壳蛋白结合；3）细菌：阻止毒素的有害作用。当病原体被“中和”后，就会被白细胞降解，再由脾脏过滤病原体，然后排出体外。
            </p>
            新冠病毒表面有不同的蛋白：膜蛋白（M）、包膜蛋白（E）以及刺突蛋白（S）。中和抗体主要通过与刺突蛋白的受体结合域（RBD）相互作用来阻止病毒与血管紧张素转换酶2（ACE2）受体的结合。新冠病毒中和抗体检测是评估抵抗新冠感染的重要指标之一。
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            antibodyOD: 0,
            antibodyRes: ''
        }
    },
    created() {
    },
    mounted() {
        // 页面置顶
        document.documentElement.scrollIntoView()

        let data = JSON.parse(localStorage.getItem('report'))
        this.antibodyOD = data.antibodyOD
        this.antibodyRes = data.antibodyRes
    },
    computed: {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
@import url(../../../static/css/global.css);

.top {
    width: 100%;
    height: 40px;
    background: #926BFF;
    border-radius: 8px 8px 0px 0px;
    padding: 9px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    width: 100%;

    .tip {
        width: 42%;
        background: #FAFCFF;
        padding: 9px 12px;
        box-sizing: border-box;
        border: 1px solid #E1E3E6;
        border-right: none;
        margin-top: -1px;
        display: flex;
        align-items: center;
        text-align: left;
    }

    .value {
        // width: 191px;
        flex: 1;
        background: #FAFCFF;
        padding: 9px 12px;
        box-sizing: border-box;
        border: 1px solid #E1E3E6;
        margin-top: -1px;
        display: flex;
        align-items: center;
    }
}
</style>
